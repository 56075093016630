import { createAction, createReducer } from '@reduxjs/toolkit'
import { AppThunk } from 'store'
import StorefrontProduct from '@local-types/products/storefrontProduct'
import products from 'apiService/products'
import { AsyncActionState, AsyncPagedActionState } from 'interfaces/global/asyncActionState'

import { RecentProduct, StorageRecentProduct } from '@local-types/products/recentProduct'
import { updateRecentProductStorage } from 'helpers/productHelpers/productHelpers'

interface FetchProductsByCategoryIdFulfilled {
	products: StorefrontProduct[]
	categoryId: string
	companyId: string | undefined
	page: number
	totalRecords: number
}

export const fetchProductsByCategoryIdPending = createAction<{ categoryId: string; companyId: string | undefined }>(
	'fetch-products-by-category-pending'
)
export const fetchAdditionalCategoryPagePending = createAction<string>('fetch-additional-category-page-pending')
export const fetchProductsByCategoryIdFulfilled = createAction<FetchProductsByCategoryIdFulfilled>(
	'fetch-products-by-category-fulfilled'
)
export const fetchAdditionalCategoryPageFulfilled = createAction<FetchProductsByCategoryIdFulfilled>(
	'fetch-additional-category-page-fulfilled'
)
export const fetchProductsByCategoryIdFailed = createAction<{ categoryId: string; companyId: string | undefined }>(
	'fetch-products-by-category-failed'
)
export const fetchProductsByCategoryId = (
	categoryId: string,
	page: number,
	companyId?: string
): AppThunk => async dispatch => {
	try {
		if (page && page > 1) dispatch(fetchAdditionalCategoryPagePending(categoryId))
		else dispatch(fetchProductsByCategoryIdPending({ categoryId, companyId }))

		const res = await products.get({ categoryIds: [categoryId], page }, companyId)
		const payload = {
			products: res.data.results,
			categoryId: categoryId,
			companyId,
			page: page || 1,
			totalRecords: res.data.totalRecords,
		}
		if (page && page > 1) dispatch(fetchAdditionalCategoryPageFulfilled(payload))
		else dispatch(fetchProductsByCategoryIdFulfilled(payload))
	} catch (err) {
		dispatch(fetchProductsByCategoryIdFailed({ categoryId, companyId }))
	}
}

interface FetchProductsByCategorySlugFulfilled {
	products: StorefrontProduct[]
	slug: string
	companyId?: string
}

export const fetchProductsByCategorySlugPending = createAction<{ slug: string; companyId: string | undefined }>(
	'fetch-products-by-category-slug-pending'
)
export const fetchProductsByCategorySlugFulfilled = createAction<FetchProductsByCategorySlugFulfilled>(
	'fetch-products-by-category-slug-fulfilled'
)
export const fetchProductsByCategorySlugFailed = createAction<{ slug: string; companyId: string | undefined }>(
	'fetch-products-by-category-slug-failed'
)

export const fetchProductsByCategorySlug = (
	slug: string,
	companyId?: string,
	pageSize?: number
): AppThunk => async dispatch => {
	try {
		dispatch(fetchProductsByCategorySlugPending({ slug, companyId }))

		const res = await products.getByCategorySlug(slug, companyId, pageSize)
		const payload = {
			products: res.data.results,
			slug: slug,
			companyId,
		}
		dispatch(fetchProductsByCategorySlugFulfilled(payload))
	} catch (err) {
		dispatch(fetchProductsByCategorySlugFailed({ slug, companyId }))
	}
}

export interface Aggregation {
	id: string
	count: number
}

interface FetchProductsFilteredFulfilled {
	products: StorefrontProduct[]
	page: number
	totalRecords: number
	aggregations?: Aggregation[]
}

export const fetchProductsFilteredPending = createAction('fetch-products-pending')
export const fetchAdditionalProductPagePending = createAction('fetch-additional-products-page-pending')
export const fetchProductsFilteredFulfilled = createAction<FetchProductsFilteredFulfilled>('fetch-products-fulfilled')
export const fetchAdditionalProductPageFulfilled = createAction<FetchProductsFilteredFulfilled>(
	'fetch-additional-product-page-fulfilled'
)
export const fetchProductsFilteredFailed = createAction<{ companyId: string | undefined }>('fetch-products-failed')
export const fetchProductsFiltered = (query: products.ProductQuery, companyId?: string): AppThunk => async dispatch => {
	const { page } = query

	try {
		if (page > 1) dispatch(fetchAdditionalProductPagePending())
		else dispatch(fetchProductsFilteredPending())

		const res = await products.get(query, companyId)
		const payload: FetchProductsFilteredFulfilled = {
			products: res.data.results,
			page,
			totalRecords: res.data.totalRecords,
			aggregations: res.data.aggregations,
		}
		if (page > 1) dispatch(fetchAdditionalProductPageFulfilled(payload))
		else dispatch(fetchProductsFilteredFulfilled(payload))
	} catch (err) {
		dispatch(fetchProductsFilteredFailed({ companyId }))
	}
}

export const fetchPreviousProductPagePending = createAction('fetch-previous-products-page-pending')
export const fetchPreviousProductPageFulfilled = createAction<FetchProductsFilteredFulfilled>(
	'fetch-previous-product-page-fulfilled'
)
export const fetchPreviousProductPageFailed = createAction<{ companyId: string | undefined }>(
	'fetch-previous-products-page-failed'
)
export const fetchPreviousProductsFiltered = (
	query: products.ProductQuery,
	companyId?: string
): AppThunk => async dispatch => {
	const { page } = query

	try {
		dispatch(fetchPreviousProductPagePending())

		const res = await products.get(query, companyId)
		const payload: FetchProductsFilteredFulfilled = {
			products: res.data.results,
			page,
			totalRecords: res.data.totalRecords,
			aggregations: res.data.aggregations,
		}

		dispatch(fetchPreviousProductPageFulfilled(payload))
	} catch (err) {
		dispatch(fetchPreviousProductPageFailed({ companyId }))
	}
}

export interface FetchProductFulfilled {
	product: StorefrontProduct
	companyId: string | undefined
}

export const fetchProductPending = createAction<{ id: string; companyId: string | undefined }>('fetch-product-pending')
export const fetchProductFulfilled = createAction<FetchProductFulfilled>('fetch-product-fulfilled')
export const fetchProductFailed = createAction<{ id: string; companyId: string | undefined }>('fetch-product-failed')
export const fetchProduct = (id: string, companyId?: string): AppThunk => async dispatch => {
	try {
		dispatch(fetchProductPending({ id, companyId }))
		const res = await products.getByIds([id], companyId)
		const product = res.data.results[0]
		if (!product) dispatch(fetchProductFailed({ id, companyId }))
		else dispatch(fetchProductFulfilled({ product, companyId }))
	} catch (err) {
		dispatch(fetchProductFailed({ id, companyId }))
	}
}

export interface FetchRecentProductsFulfilled {
	products: Array<RecentProduct>
	companyId: string
	userId: string
}

export const fetchRecentProductsPending = createAction<string>('fetch-recent-products-pending')
export const fetchRecentProductsFulfilled = createAction<FetchRecentProductsFulfilled>(
	'fetch-recent-products-fulfilled'
)
export const fetchRecentProductsFailed = createAction<string>('fetch-recent-products-failed')
export const fetchRecentProducts = (
	companyId: string,
	userId: string,
	recentStorageProducts: StorageRecentProduct[]
): AppThunk => async dispatch => {
	dispatch(fetchRecentProductsPending(companyId))

	if (recentStorageProducts.length === 0) {
		dispatch(fetchRecentProductsFulfilled({ userId, companyId, products: [] }))
		return
	}

	try {
		const ids = recentStorageProducts.map(x => x.id)
		const res = await products.getByIds(ids, companyId)
		const recentItems: RecentProduct[] = res.data.results.reduce((prev, curr) => {
			const storageProd = recentStorageProducts.find(x => x.id === curr.id)
			if (storageProd)
				prev.push({
					date: storageProd.date,
					product: curr,
				})
			return prev
		}, [] as RecentProduct[])
		dispatch(fetchRecentProductsFulfilled({ companyId, userId, products: recentItems }))
	} catch (err) {
		dispatch(fetchRecentProductsFailed(companyId))
	}
}

export const updateRecentProducts = createAction<{ userId: string; product: StorefrontProduct }>(
	'update-recent-products'
)
export const setProductsPage = createAction<number>('set-products-page')

export interface ProductReducer {
	byCategoryId: {
		[key: string]: {
			status: AsyncPagedActionState
			products: StorefrontProduct[]
			companyId: string | undefined
			totalRecords: number
		}
	}
	byId: {
		[key: string]: {
			status: AsyncActionState
			product: StorefrontProduct | null
			companyId: string | undefined
		}
	}
	filteredProductList: {
		status: AsyncPagedActionState
		products: StorefrontProduct[]
		page: number
		totalRecords: number
	}
	byCategorySlug: {
		[key: string]: {
			status: AsyncActionState
			products: StorefrontProduct[]
			companyId: string | undefined
		}
	}
	recentlyViewed: {
		status: AsyncActionState
		products: RecentProduct[]
		userId: string | null
		companyId: string | null
	}
}

const initialState: ProductReducer = {
	byCategoryId: {},
	byId: {},
	filteredProductList: {
		status: 'passive',
		products: [],
		page: 1,
		totalRecords: 0,
	},
	byCategorySlug: {},
	recentlyViewed: {
		status: 'passive',
		products: [],
		userId: null,
		companyId: null,
	},
}

const productReducer = createReducer(initialState, builder =>
	builder
		.addCase(fetchProductsByCategoryIdPending, (store, action) => {
			store.byCategoryId[action.payload.categoryId] = {
				status: 'pending',
				products: [],
				companyId: action.payload.companyId,
				totalRecords: 0,
			}
		})
		.addCase(fetchAdditionalCategoryPagePending, (store, action) => {
			if (store.byCategoryId[action.payload]) store.byCategoryId[action.payload].status = 'additional-page-pending'
		})
		.addCase(fetchProductsByCategoryIdFulfilled, (store, action) => {
			store.byCategoryId[action.payload.categoryId] = {
				status: 'fulfilled',
				products: action.payload.products,
				companyId: action.payload.companyId,
				totalRecords: action.payload.totalRecords,
			}

			action.payload.products.forEach(prod => {
				store.byId[prod.id] = {
					status: 'fulfilled',
					product: prod,
					companyId: action.payload.companyId,
				}
			})
		})
		.addCase(fetchAdditionalCategoryPageFulfilled, (store, action) => {
			const products = store.byCategoryId[action.payload.categoryId].products.concat(action.payload.products)
			store.byCategoryId[action.payload.categoryId] = {
				...store.byCategoryId[action.payload.categoryId],
				status: 'fulfilled',
				products,
			}

			action.payload.products.forEach(prod => {
				store.byId[prod.id] = {
					status: 'fulfilled',
					product: prod,
					companyId: action.payload.companyId,
				}
			})
		})
		.addCase(fetchProductsByCategoryIdFailed, (store, action) => {
			store.byCategoryId[action.payload.categoryId] = {
				status: 'failed',
				products: [],
				companyId: action.payload.companyId,
				totalRecords: 0,
			}
		})
		.addCase(fetchProductPending, (store, action) => {
			store.byId[action.payload.id] = {
				status: 'pending',
				product: null,
				companyId: action.payload.companyId,
			}
		})
		.addCase(fetchProductFulfilled, (store, action) => {
			store.byId[action.payload.product.id] = {
				status: 'fulfilled',
				product: action.payload.product,
				companyId: action.payload.companyId,
			}
		})
		.addCase(fetchProductFailed, (store, action) => {
			store.byId[action.payload.id] = {
				status: 'failed',
				product: null,
				companyId: action.payload.companyId,
			}
		})
		.addCase(fetchProductsFilteredPending, store => {
			store.filteredProductList = {
				...store.filteredProductList,
				products: [],
				status: 'pending',
			}
		})
		.addCase(fetchAdditionalProductPagePending, store => {
			store.filteredProductList = {
				...store.filteredProductList,
				status: 'additional-page-pending',
			}
		})
		.addCase(fetchProductsFilteredFulfilled, (store, action) => {
			store.filteredProductList = {
				status: 'fulfilled',
				products: action.payload.products,
				page: action.payload.page,
				totalRecords: action.payload.totalRecords,
			}
		})
		.addCase(fetchAdditionalProductPageFulfilled, (store, action) => {
			// Filter out duplicates
			const products = store.filteredProductList.products
				.concat(action.payload.products)
				.filter((value, index, self) => index === self.findIndex(t => t.id === value.id))

			store.filteredProductList = {
				status: 'fulfilled',
				products: products,
				page: action.payload.page,
				totalRecords: action.payload.totalRecords,
			}
		})
		.addCase(fetchPreviousProductPageFulfilled, (store, action) => {
			const products = action.payload.products.concat(store.filteredProductList.products)

			store.filteredProductList = {
				status: 'fulfilled',
				products: products,
				page: store.filteredProductList.page,
				totalRecords: action.payload.totalRecords,
			}
		})
		.addCase(fetchPreviousProductPagePending, store => {
			store.filteredProductList = {
				...store.filteredProductList,
				status: 'additional-page-pending',
			}
		})
		.addCase(fetchPreviousProductPageFailed, store => {
			store.filteredProductList = {
				...store.filteredProductList,
				status: 'failed',
			}
		})
		.addCase(fetchProductsFilteredFailed, store => {
			store.filteredProductList = {
				...store.filteredProductList,
				status: 'failed',
			}
		})
		.addCase(fetchProductsByCategorySlugPending, (store, action) => {
			store.byCategorySlug[action.payload.slug] = {
				companyId: action.payload.companyId,
				products: [],
				status: 'pending',
			}
		})
		.addCase(fetchProductsByCategorySlugFulfilled, (store, action) => {
			store.byCategorySlug[action.payload.slug] = {
				...store.byCategorySlug[action.payload.slug],
				companyId: action.payload.companyId,
				status: 'fulfilled',
				products: action.payload.products,
			}
		})
		.addCase(fetchProductsByCategorySlugFailed, (store, action) => {
			store.byCategorySlug[action.payload.slug] = {
				products: [],
				companyId: action.payload.companyId,
				status: 'failed',
			}
		})
		.addCase(fetchRecentProductsPending, store => {
			store.recentlyViewed.status = 'pending'
		})
		.addCase(fetchRecentProductsFulfilled, (store, action) => {
			store.recentlyViewed.status = 'fulfilled'
			store.recentlyViewed.userId = action.payload.userId
			store.recentlyViewed.companyId = action.payload.companyId
			store.recentlyViewed.products = action.payload.products
			store.recentlyViewed.products = store.recentlyViewed.products.concat().sort((a, b) => {
				return b.date - a.date
			})
		})
		.addCase(fetchRecentProductsFailed, store => {
			store.recentlyViewed.status = 'failed'
			store.recentlyViewed.products = []
		})
		.addCase(updateRecentProducts, (store, action) => {
			const { product, userId } = action.payload
			const recentProduct = store.recentlyViewed.products.find(x => x.product.id === product.id)
			store.recentlyViewed.userId = userId
			if (recentProduct) recentProduct.date = new Date().getTime()
			else {
				store.recentlyViewed.products.push({ product, date: new Date().getTime() })
				store.recentlyViewed.products = store.recentlyViewed.products.concat().sort((a, b) => {
					return b.date - a.date
				})
			}

			store.recentlyViewed.products = updateRecentProductStorage(
				store.recentlyViewed.userId as string,
				store.recentlyViewed.products
			)
			store.recentlyViewed.products = store.recentlyViewed.products.concat().sort((a, b) => {
				return b.date - a.date
			})
		})
		.addCase(setProductsPage, (store, action) => {
			store.filteredProductList = {
				...store.filteredProductList,
				page: action.payload,
			}
		})
)

export default productReducer
